
import './styles/quasar.sass'
import './styles/blog.sass'
import './styles/wordpress.sass'
import '@quasar/extras/material-icons/material-icons.css'
import Notify from 'quasar/src/plugins/Notify.js';

// To be used on app.use(Quasar, { ... })
export default {
  config: {
    dark: true
  },
  plugins: {
    Notify
  }
}