import { createRouter, createWebHistory } from 'vue-router'

//import LandingView from '../views/LandingView.vue'

// routes with custom property 'label' will be included in TheMenu component
// custom property 'spacer' will be used to add a spacer after it
const routes = [
  {
    path: '/',
    name: 'root',
    //component: LandingView
    component: () => import(/* webpackChunkName: 'landing' */ '../views/LandingView.vue')
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: () => import(/* webpackChunkName: "welcome" */ '../views/WelcomeView.vue')
  },
  {
    path: '/turingtest',
    name: 'turingtest',
    component: () => import(/* webpackChunkName: "turingtest" */ '../views/TuringtestView.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import(/* webpackChunkName: "signup" */ '../views/SignUpView.vue')
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    label: 'The RECORD SHELF',
    label_de: 'plattenregal',
    path: '/shelf',
    name: 'The RECORD SHELF',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "The RECORD SHELF" */ '../views/RecordShelfView.vue')
  },
  {
    label: 'blog',
    path: '/blog',
    name: 'blog',
    component: () => import(/* webpackChunkName: "blog" */ '../views/BlogView.vue')
  },
  {
    path: '/blog/:slug',
    name: 'post',
    props: true,
    component: () => import(/* webpackChunkName: "post" */ '../views/BlogPostView.vue')
  },
  {
    path: '/podcast',
    name: 'podcast',
    label: 'podcast',
    component: () => import(/* webpackChunkName: "podcast" */ '../views/PodcastView.vue')
  },
  {
    path: '/podcast/:episode',
    name: 'podcast-episode',
    props: true,
    component: () => import(/* webpackChunkName: "podcast-episode" */ '../views/PodcastEpisodeView.vue')
  },
  {
    label: 'manual',
    label_de: 'handbuch',
    spacer: true,
    path: '/manual',
    name: 'manual',
    component: () => import(/* webpackChunkName: "manual" */ '../views/ManualView.vue')
  },
  {
    label: 'about',
    label_de: 'über',
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    label: 'imprint',
    label_de: 'impressum',
    path: '/imprint',
    name: 'imprint',
    component: () => import(/* webpackChunkName: "legal" */ '../views/LegalView.vue')
  },
  {
    path: '/record/:id/',
    name: 'record',
    component: () => import(/* webpackChunkName: "record" */ '../views/RecordView.vue')
  },
  {
    path: '/record/:id/add',
    name: 'add sample',
    component: () => import(/* webpackChunkName: "add sample" */ '../views/AddSampleView.vue')
  },
  {
    path: '/sample/:id/',
    name: 'view sample',
    component: () => import(/* webpackChunkName: "view sample" */ '../views/ViewSampleView.vue')
  },
  {
    path: '/record/:id/addAspect',
    name: 'add aspect',
    component: () => import(/* webpackChunkName: "add aspect" */ '../views/AddAspectView.vue')
  },
  {
    path: '/record/:id/play/:sample',
    alias: '/record/:id/play',
    name: 'play record',
    component: () => import(/* webpackChunkName: "play record" */ '../views/PlayRecordView.vue')
  },
  {
    path: '/addRecord',
    name: 'add record',
    component: () => import(/* webpackChunkName: "add record" */ '../views/AddRecordView.vue')
  },
  {
    path: '/:path(.*)*',
    name: '404',
    component: () => import(/* wecpackCunkName: "under construction" */ '../views/LandingView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
