const { createApp } = require('vue');
import { createPinia } from 'pinia';
import router from './router'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { createAuth0 } from '@auth0/auth0-vue'
import App from "./App.vue"

const pinia = createPinia()
const app = createApp(App)

app.config.globalProperties.$apiHost = 'api.goldenrecordstudios.earth'
app.config.globalProperties.$apiUrl = 'https://' + app.config.globalProperties.$apiHost
app.config.globalProperties.$wsApiUrl = 'wss://' + app.config.globalProperties.$apiHost
// app.config.globalProperties.$fileUrl = app.config.globalProperties.$apiUrl
// use CDN instead
app.config.globalProperties.$fileUrl = 'https://cdn.goldenrecordstudios.earth'

app.config.globalProperties.$aspectColors = [
  "204,121,167",
  "230,159,0",
  "86,180,233",
  "0,158,115",
  "240,228,66",
  "0,114,178",
  "213,94,0",
  "0,0,0"
]

app.config.globalProperties.$recordMap = [
  [0,0,0,0,0,0,1,1,1,1,1,1,0,0,0,0,0,0],
  [0,0,0,0,1,1,1,1,1,1,1,1,1,1,0,0,0,0],
  [0,0,0,1,1,1,1,1,1,1,1,1,1,1,1,0,0,0],
  [0,0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,0,0],
  [0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,0],
  [0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,0],
  [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],
  [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],
  [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],
  [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],
  [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],
  [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],
  [0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,0],
  [0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,0],
  [0,0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,0,0],
  [0,0,0,1,1,1,1,1,1,1,1,1,1,1,1,0,0,0],
  [0,0,0,0,1,1,1,1,1,1,1,1,1,1,0,0,0,0],
  [0,0,0,0,0,0,1,1,1,1,1,1,0,0,0,0,0,0]
]

app.config.globalProperties.$maxAspectsPerRecord = 7
app.config.globalProperties.$maxSamplesPerUserPerRecord = 324
app.config.globalProperties.$maxAspectLength = 30
app.config.globalProperties.$maxNickNameLength = 8
app.config.globalProperties.$maxPlaceLength = 25
app.config.globalProperties.$maxTextSampleLength = 365
app.config.globalProperties.$maxClones = 5

app.config.globalProperties.$maxRecordTopicLength = 30
app.config.globalProperties.$maxRecordInfoLength = 750
app.config.globalProperties.$addRecordMinAspects = 2
app.config.globalProperties.$addRecordMinElapsedHours = 24
app.config.globalProperties.$addRecordMinSamplesByOthersOnPriorRecord = 3

app.config.globalProperties.$reservationSeconds = 60

app.config.globalProperties.$maxSampleAudioLength = 35.0
app.config.globalProperties.$maxSampleVideoLength = 15.0

app.config.globalProperties.$tokenCostAddAspect = 30
app.config.globalProperties.$tokenCostAddRecord = 200
app.config.globalProperties.$tokenGainPlaceSample = 1
app.config.globalProperties.$tokenGainCloneSample = 5
app.config.globalProperties.$tokenGainCreateSample = 10

app.config.globalProperties.$recaptchaAllowedMistakes = 5


app.config.globalProperties.$namespace = 'https://goldenrecordstudios.earth/'
app.config.globalProperties.$auth0Domain = 'alexh-name.eu.auth0.com'
app.config.globalProperties.$auth0ClientID = 'eW9VyXPGp1mCTyV5ukSbcIFUHJsghYWF'
app.config.globalProperties.$auth0Audience = 'https://grs.dev.alexh.name/api'

app.config.globalProperties.$thumbStyle = {
  right: '2px',
  borderRadius: '5px',
  backgroundColor: '#ff5a00',
  width: '5px',
  height: '4px',
  opacity: '0.75'
}

app.config.globalProperties.$appBgColors = []

app.config.globalProperties.$blogApi = 'https://grsblog.matthaei-und-konsorten.de/wp-json/wp/v2/'

app
  .use(router)
  .use(Quasar, quasarUserOptions)
  .use(VueAxios, axios)
  .use(createAuth0({
    domain: app.config.globalProperties.$auth0Domain,
    client_id: app.config.globalProperties.$auth0ClientID,
    redirect_uri: window.location.origin + '/signup',
    audience: app.config.globalProperties.$auth0Audience,
    scope: 'openid email'
  }))
  .use(pinia)
  .mount("#app");
