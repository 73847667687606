<template>
  <div class="video" v-if="$route.path === '/' || $route.name === 'under construction'">
    <video autoplay loop playsinline disablepictureinpicture muted>
      <source type="video/mp4" :src="this.$fileUrl + '/landing-2.mp4'"/>
      <source type="video/webm" :src="this.$fileUrl + '/landing-2.webm'"/>
    </video>
  </div>
  <q-layout :class="{landing: $route.path === '/'}">
    <q-page-container :class="{landing: $route.path === '/'}">
      <q-page padding class="full-height column no-wrap justify-between" :style="BG">
        <router-view @bg-color="setBgColor"/>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
export default {
  data() {
    return {
      bgColors: []
    }
  },
  computed: {
    BG() {
      if (this.bgColors.length !== 0) {
        const style = {
          'background': `linear-gradient(to bottom left, rgba(${this.bgColors[0]}, 0.5), rgba(${this.bgColors[1]}, 0.5))`
        };
        return style;
      } else {
        return {};
      }
    }
  },
  methods: {
    setBgColor(colors) {
      this.bgColors = colors;
    }
  }
}
</script>

<style lang="sass">
html, body
  background-color: black
  font-family: grs

.q-page-container, .active
  color: $primary

@media screen and (min-height: 500px)
  #app
    //max-width: $recordcircumference
    margin: auto

.main-content
  color: white

.aspect
  text-shadow: 0.07em 0 3px black, 0 0.07em 3px black, -0.07em 0 3px black, 0 -0.07em 3px black
  color: white

.aspectColor0
  background: $aspectColor0

.aspectColor1
  background: $aspectColor1

.aspectColor2
  background: $aspectColor2

.aspectColor3
  background: $aspectColor3

.aspectColor4
  background: $aspectColor4

.aspectColor5
  background: $aspectColor5

.aspectColor6
  background: $aspectColor6

.aspectColor7
  background: $aspectColor7
  border: solid 1px white

.unselectable
  user-select: none
  -moz-user-select: none
  -khtml-user-select: none
  -webkit-user-select: none
  -o-user-select: none

.text-center
  text-align: center

.main .q-badge, .main.q-badge, .q-badge.user-data
  background: white

.user-data
  font-family: 'fake_receipt'

.badge
  border: solid 1px
  border-radius: 4px
  background: white
  color: black

.positive
  color: $positive

.warning
  color: $warning

.negative
  color: $negative

.user-data .muted
  opacity: 0.5

.grs-font
  font-family: 'grs'

.lowercase
  text-transform: lowercase

.q-stepper--horizontal
  background: transparent
  max-width: 80ch
  margin-left: auto
  margin-right: auto
  width: 100%

.q-stepper--horizontal .q-stepper__step-inner
  padding-top: 0

.transparent
  opacity: 0.33

div.form input, div.form textarea
  font-family: fake_receipt

div.form textarea
  resize: none
  overflow: hidden

a
  color: $primary

.textBox
  text-align: justify

.textBox p, .textBox .form
  max-width: 80ch

.textBox .form
  width: 100%

.badge .q-item__label--caption
  color:  rgba(0,0,0,0.54) !important
.badge .q-item__section--side
  color: $negative !important
.badge .q-item__section--side.positive
  color: $positive !important

.q-layout-margin
  @media (max-width: $breakpoint-xs-max)
    margin: 8px
  @media (min-width: $breakpoint-sm-min) and (max-width: $breakpoint-md-max)
    margin: 16px
  @media (min-width: $breakpoint-lg-min)
    margin: 24px
</style>

<style>
  @import '@/assets/fonts.css';
</style>

<style lang="sass" scoped>
.video
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  overflow: hidden

.video video
  object-fit: cover
  object-position: 50% 50%
  width: 100%
  height: 100%
</style>
// TODO: social cards
